<template>
    <div class="flex flex-col gap-2 flex-1" v-allow="'client.read'">
        <div class="flex justify-end">
            <Button @click="handleShowModal" v-if="checkPermission('client.edit')" text="Add User" type="primary" />
        </div>
        <vue-good-table class="flex-1" :columns="columns" :rows="filteredRows(rows)" :isLoading="loadingState.userList" :pagination-options="this.paginationOptions">
            <template #column-filter="{ column }">
                <Dropdown   
                    class="filter-dropdown text-center" 
                    placeholder="Select"
                    v-if="column?.filterOptions?.enabled"
                    :options="getFilterOptions(column)" 
                    v-model="filters[column.field]"
                    @input="(value) => { updateFilters(column, value)}" 
                    :config="{ limit: 1 }" 
                    :colors="{ optionText: 'black' }"
                    :reposition="true"
                    searchable
                    multiple
                    maxWidth="100%"
                    width="100px"
                    minWidth="100%"
                >
                </Dropdown> 
            </template>
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field === 'role_ids'" class="flex gap-3">
                    <div class="flex gap-2">
                        <template v-for="role in props.row.role_ids">
                            <div class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                                :title="role.title" :key="role.role_id">{{role.title}}</div>
                        </template>
                    </div>
                </div>
                <div v-else-if="props.column.field === 'group_ids'" class="flex gap-3">
                    <div class="flex gap-2">
                        <template v-for="group in props.row.group_ids">
                            <div class="bg-blue-100 text-primary text-sm shadow truncate px-1 rounded font-normal"
                                :title="group.title" :key="group.group_id">{{group.title}}</div>
                        </template>
                    </div>
                </div>
                <div v-else-if="props.column.field === 'action'" class="flex justify-center gap-3">
                    <button @click="updateUserModal(props.row)" class="h-6 w-6 disabled:opacity-50 "
                        :class="(loadingState.deleteUser || !checkPermission('client.edit') )&& 'pointer-events-none cursor-wait' "
                        :disabled="loadingState.deleteUser || !checkPermission('client.edit')">
                        <font-awesome-icon icon="edit" class="text-green-600 text-lg" />
                    </button>
                    <button @click="deleteUser(props.row)" class="h-6 w-6 disabled:opacity-50 "
                        :class="(loadingState.deleteUser || !checkPermission('client.edit') ) && 'pointer-events-none cursor-wait' "
                        :disabled="loadingState.deleteUser || !checkPermission('client.edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="text-red-600 hover:text-red-700">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            </template>
            <template #loadingContent>
                <div class="w-full py-10 justify-center flex">
                    <Loader />
                </div>
            </template>  
        </vue-good-table>
        <AddUserModal :user="userData" ref="adduserModal" @handleSubmit="handleSubmit($event)" :roles="roles" :groups="groups" :loadingState="loadingState" />
         <ModalConfirm title="Are you sure?" message="Please confirm you're about to delete the field." ref="confirm-popup"> </ModalConfirm>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import AddUserModal from "@shared/components/addUserModal";
import axios from "@/axios";
import Loader from "@shared/loader"
import Dropdown from "@shared/components/dropdown-base";
const ModalConfirm = () => import("@shared/modal-confirm");
import { checkPermission } from "@shared/utils/functions";
import { uniq } from "lodash";

export default {
    name: "users",
    components: {
        Button,
        Dropdown,
        AddUserModal,
        Loader,
        ModalConfirm
    },
    props: {},
    data() {
        return {
            tenant_id: null,
            clientId: null,
            columns: [
                {
                    label: "User Name",
                    field: "user_name",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "Email",
                    field: "email",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "User Position",
                    field: "position",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "Telephone",
                    field: "mobile",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "Role",
                    field: "role_ids",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "Group",
                    field: "group_ids",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        isLoading: false,
                    },
                },
                {
                    label: "Action",
                    field: 'action',
                },
            ],
            rows: [],
            roles: [],
            groups: [],
            loadingState: {
                roles: false,
                groups: false,
                userList: false,
                deleteUser: false,
            },
            userData: {
                username: "",
                email: "",
                roles: [],
                groups: [],
                mobile: '',
                position: ''
            },
            isEdit: false,
            paginationOptions:{
                enabled:true,
                perPage: 10,
                perPageDropdown: [10, 20, 30,40,50],
                dropdownAllowAll: false,
            },
            filters:{
                user_name: [],
                email: [],
                position: [],
                mobile: [],
                role_ids: [],
                group_ids: []
            },
        };
    },
    async mounted(){
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.tenant_id = this.$store.getters.getTenantId;
        this.clientId = this.$route.params.clientId;
        await this.getUsersLists()
    },
    methods: {
        checkPermission,
        async fetchRoles() {
            this.loadingState.roles = true;
            try {
                let { data } = await axios.get(`role/client/${this.clientId}`);
                this.roles = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingState.roles = false;
        },
        async fetchGroups() {
            this.loadingState.groups = true;
            try {
                let { data } = await axios.get(`group/client/${this.clientId}`);
                this.groups = data;
            } catch (error) {
                console.log(error, ">>>>error");
            }
            this.loadingState.groups = false;
        },
        async handleShowModal() {
            this.isEdit = false
            this.userData = {
                username: "",
                email: "",
                roles: [],
                groups: [],
                mobile: '',
                position: ''
            };
            this.$refs["adduserModal"].showUserModal();
            await this.fetchRoles();
            await this.fetchGroups();
        },
        async getUsersLists() {
            this.loadingState.userList = true;
            let url = `user/client/${this.clientId}`;
            try {
                let { data } = await axios.get(url);
                this.rows = data;
            } catch (error) {
                console.log(error, "<<<<error while fetching users");
            }
            this.loadingState.userList = false;
        },
        async deleteUser(user) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This user  will be deleted permanently (From final report alert emails also). Please confirm to continue deleting this user.",
                buttonText: 'Confirm'
            });
            if (promise) {
                this.loadingState.deleteUser = true
                try {
                    const url = `user/${user.id}/client`;
                    let payload = {
                        client_id: this.clientId,
                    }
                    let {data} = await axios.delete(url, {
                        data: payload
                    });
                    this.$toast.success(data.message || "user Deleted");
                    await this.getUsersLists()
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Unable to delete user");
                    console.log("error", error);
                }
                this.loadingState.deleteUser = false
            }
            this.$refs["confirm-popup"].close();
        },
        async handleSubmit(event){
            this.isEdit ? this.updateUser(event) : this.addUser(event)
        },
        async addUser(userData) {
            let payload = {
                email: userData.email.trim(),
                user_name: userData.username,
                group_ids: userData.groups.map(el => ({group_id: el.id})),
                role_ids: userData.roles.map(el => ({role_id: el.id})),
                mobile: userData.mobile,
                position: userData.position,
                client_id: this.clientId,
            };
            try {
                let { data } = await axios.post("user/client", payload);
                this.$toast.success(data.message || "User created");
                await this.getUsersLists()
                this.$refs["adduserModal"].closeModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to add user");
            }
        },
        async updateUserModal(user){
            this.isEdit = true
            this.userData = {
                id:user.id,
                username: user.user_name,
                email: user.email,
                roles: user.role_ids.map(el => ({...el, id:el.role_id})),
                groups: user.group_ids.map(el => ({...el, id:el.group_id})),
                mobile: user.mobile,
                position: user.position
            };
            this.$refs["adduserModal"].showUserModal();
            await this.fetchRoles();
            await this.fetchGroups();
        },
        async updateUser(userData) {
            try {
                let { data } = await axios.put("user/client", {
                    id: userData.id,
                    email: userData.email,
                    user_name: userData.username,
                    group_ids: userData.groups.map(el => ({group_id: el.id})),
                    role_ids: userData.roles.map(el => ({role_id: el.id})),
                    position: userData.position,
                    client_id: this.clientId,
                });
                this.$toast.success(data.message || "user updated");
                this.$refs["adduserModal"].closeModal();
                await this.getUsersLists()
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to update user");
                console.log("error", error);
            }
        },
        getFilterOptions(column){
            let result = this.rows.length ? this.rows.filter((el)=> el[column.field] != null ).map((el)=>el[column.field]): [];
            if(column.field === "role_ids" || column.field === "group_ids"){
                return uniq(result.flat().map((el)=>el.title)) || []
            }
            return uniq(result)
        },
        updateFilters(column, value) {
            if (this.filters?.[column.field]) this.filters[column.field] = value;
        },
        filteredRows(result){
            for (let filter in this.filters) {
                if (this.filters[filter]?.length) {
                    if(filter==="role_ids" || filter==="group_ids"){
                        for(let fil of this.filters[filter]){
                            result = this.rows.filter((row)=>{
                                for(let obj of row[filter]){
                                    if(obj.title===fil){
                                        return true;
                                    }
                                }
                                
                            })
                        }
                    }
                    else{
                     result = result.filter((el) => this.filters[filter].includes(el[filter]));
                    }
                }
            }
            return result;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@shared/assets/mixins/mixins.scss";
@include tableComponent;
.filter-dropdown {
    box-shadow: none !important;
    @apply border-transparent border  bg-white;
}
</style>
